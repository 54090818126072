<template>
  <div class="product-inquiry-reg-modal">
    <sweet-modal overlay-theme="dark" ref="modal" :hide-close-button="true">
      <div class="modal-title padding-bottom-40">
        <div class="flex-column" style="gap: 4px;">
          <div class="h6 main">문의하기</div>
          <div v-if="step === 1" class="body4 sub">파트너 정보와 자주묻는질문을 확인해주세요.</div>
        </div>
        <i class="material-icons unselect btn-close" @click="close">close</i>
      </div>
      <div class="modal-header">
        <div class="size-16 weight-500 sub2">문의하기</div>
        <i class="material-icons unselect btn-close" @click="close">close</i>
      </div>

      <template v-if="step === 1">
        <div class="partner-info">
          <div class="body3-bold">{{ product.partner.nickname }}</div>
          <div class="flex-align body5 sub2" style="gap: 8px">
            <div>법인사업자</div>
            <div class="line-bar"></div>
            <div>
              템플릿 <span class="primary">{{ product.partner.product_count }}</span
              >개
            </div>
          </div>
        </div>
        <product-faq
          :faq="product.faq"
          :faqContainerStyle="faqContainerStyle"
          :faqTitleStyle="isMobile ? faqTitleStyle : {}"
        ></product-faq>
        <div class="faq-btn">
          <button class="button is-primary" style="width:100%;height:52px;" @click="step = 2">
            문의하기
          </button>
        </div>
      </template>
      <template v-else>
        <div class="content">
          <div class="label">문의내용</div>
          <textarea
            class="textarea margin-bottom-16"
            placeholder="궁금하신 사항을 간단히 작성해주세요.
    담당자가 확인 후 연락드리겠습니다."
            v-model="value.question_content"
            @input="(e) => (question_content = e.target.value)"
          ></textarea>

          <div class="flex-align margin-bottom-24" style="gap:28px">
            <lp-check-bool :checkedVal.sync="value.is_secret" label="비밀글"></lp-check-bool>
            <lp-check-bool :checkedVal.sync="value.answer_email" label="이메일로 답변 받기"></lp-check-bool>
          </div>
          <template v-if="!isLogin">
            <div class="divider"></div>
            <div class="grid-form">
              <div class="item">
                <div class="label">성함</div>
                <input type="text" class="input" v-model="value.name" @input="(e) => (value.name = e.target.value)" />
              </div>
              <div class="item">
                <div class="label">연락처</div>
                <input
                  type="text"
                  class="input"
                  v-model="value.phone"
                  @input="(e) => (value.phone = autoHyphenPhone(e.target.value))"
                />
              </div>
              <div class="item">
                <div class="label">이메일</div>
                <input type="text" class="input" v-model="value.email" @input="(e) => (value.email = e.target.value)" />
              </div>
            </div>

            <div class="flex-align check-pc">
              <lp-check-bool :checkedVal.sync="agree" label="이용약관 및 개인정보처리방침에 동의하기"></lp-check-bool>
              <div class="size-14 primary">(필수)</div>
              <a class="sub3 size-14 text-underline margin-left-8 unselect" href="privacy" target="_blank">약관보기</a>
            </div>
            <div class="flex-align check-mobile">
              <lp-check-bool
                :checkedVal.sync="agree"
                label="이용약관 및 개인정보처리방침에 동의하기"
                style="width:fit-content"
                :labelStyle="{ fontSize: '12px', color: '#555' }"
              ></lp-check-bool>
              <span class="size-12 primary">(필수)</span>
              <a class="sub3 size-12 text-underline margin-left-8 unselect" href="privacy" target="_blank">약관보기</a>
            </div>
          </template>

          <div class="flex-between body2-medium" style="margin-top:40px">
            <button class="button" style="width:50%;height:48px;" @click="close">취소</button>
            <button class="button is-primary margin-left-16" style="width:50%;height:48px;" @click="saveData">
              {{ inquiryId ? '수정완료' : '등록하기' }}
            </button>
          </div>
        </div>
      </template>
    </sweet-modal>
  </div>
</template>

<script>
import LpCheckBool from '../module/LpCheckBool';
import UserAPIMixin from '../../mixins/UserAPIMixin';
import ServiceAdminAPIMixin from '../../mixins/ServiceAdminAPIMixin';
import { SweetModal } from 'sweet-modal-vue';
import ProductFaq from '../page/ProductFaq.vue';

export default {
  name: 'ProductInquiryModal',
  mixins: [UserAPIMixin, ServiceAdminAPIMixin],
  components: {
    LpCheckBool,
    SweetModal,
    ProductFaq,
  },
  props: {
    inquiryId: {
      type: Number,
    },
    product: {
      type: Object,
    },
  },
  mounted() {
    if (this.product && this.product.faq.length > 0) {
      this.step = 1;
    }
  },
  data() {
    return {
      faqContainerStyle: {
        padding: '16px 0 0',
        gap: '0',
      },
      faqTitleStyle: {
        padding: '0 16px',
      },
      agree: false,
      value: {
        question_content: '',
        is_secret: false,
        answer_email: true,
        name: '',
        email: '',
        phone: '',
      },
      step: 2,
    };
  },
  computed: {
    req() {
      const user_id = this.$store.getters.user.user_id;
      const admin_id = this.$store.getters.admin.id;
      let req = this.$axios;
      if (user_id > 0) {
        req = this.request.user;
      } else if (admin_id > 0) {
        req = this.request.serviceAdmin;
      }
      return req;
    },
    url() {
      let url = `/product/${this.$route.query.id}/inquiry`;
      if (!this.isLogin) {
        url = '/public' + url;
      }
      if (this.inquiryId) {
        url += `/${this.inquiryId}`;
      }
      return url;
    },
  },
  methods: {
    open() {
      if (this.inquiryId) {
        this.getData();
      } else {
        this.init();
      }
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
      setTimeout(() => {
        this.step = 1;
      }, 300);
    },
    init() {
      this.value = {
        question_content: '',
        is_secret: false,
        answer_email: true,
        name: '',
        email: '',
        phone: '',
      };
    },
    getData() {
      this.request.user.get(this.url).then((res) => {
        this.value = res.data;
      });
    },
    isValid() {
      if (this.isLogin) {
        this.agree = true;
      }

      const patternEmail = /[0-9a-zA-Z][_0-9a-zA-Z-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+){1,2}$/;

      let msg = '';
      if (this.value.question_content.trim() === '') {
        msg = '문의내용을 입력해주세요.';
      } else if (!this.isLogin) {
        if (this.value.name.trim() === '') {
          msg = '성함을 입력해주세요.';
        } else if (this.value.phone.trim() === '') {
          msg = '연락처를 입력해주세요.';
        } else if (this.value.phone.length < 12) {
          msg = '연락처를 제대로 입력해주세요.';
        } else if (this.value.email.trim() === '') {
          msg = '이메일을 입력해주세요.';
        } else if (!patternEmail.test(this.value.email)) {
          msg = '이메일을 올바르게 입력해주세요.';
        }
      }

      if (!this.agree) {
        msg = '약관에 먼저 동의해주세요.';
      }

      if (msg !== '') {
        this.toast(msg);
      }

      return msg === '';
    },
    saveInquiry(res, type) {
      if (res.status === 200) {
        if (type === 'patch') {
          this.toast('문의내용이 수정되었습니다.');
        } else {
          this.toast('문의내용이 등록되었습니다.');
        }
        this.$emit('update');
        this.close();
      }
    },
    saveData() {
      this.value.channel = '플랫폼파이';
      this.value.product_id = this.$route.query.id;

      if (this.isValid()) {
        if (this.inquiryId) {
          // 수정
          this.req.patch(this.url, this.value).then((res) => {
            this.saveInquiry(res, 'patch');
          });
        } else {
          this.req.post(this.url, this.value).then((res) => {
            this.saveInquiry(res, 'post');
          });
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.label
  font-size 14px
  font-weight 500
  margin-bottom 6px
.grid-form
  display grid
  grid-template-columns 160px 160px 1fr
  gap 12px
  margin-bottom 16px
.divider
  width 100%
  border-bottom 1px solid #eeeeee
  margin-bottom 24px
.button
  font-size 16px

.modal-title
  display flex
  align-items flex-start
  justify-content space-between

.partner-info
  padding 20px 0
  border-bottom 1px solid #E3E3E3
.line-bar
  width 1px
  height 16px
  background #E3E3E3
.faq-btn
  width 100%
  margin-top 40px
.textarea::placeholder
  white-space pre-line
  font-size 14px
.modal-header
  display none
.check-pc
  display flex
.check-mobile
  display none
@media (max-width:600px)
  .check-mobile
    display flex
  .check-pc
    display none
  .modal-title
    display none
  .content
    padding 0 16px 120px 16px
  .grid-form
    grid-template-columns auto
  .item:nth-child(1)
    grid-column-start 1
    grid-column-end 2
  .item:nth-child(2)
    grid-column-start 2
    grid-column-end 2
  .item:nth-child(3)
    grid-column-start 1
    grid-column-end 3
  .modal-header
    display flex
    align-items center
    justify-content center
    border-bottom 1px solid #eeeeee
    width 100%
    height 50px
    position fixed
    top 0
    left 0
    .btn-close
      position absolute
      right 16px
      top 15px
  .partner-info
    padding 20px 24px
  .faq-btn
    position fixed
    bottom 0
    left 0
    width 100%
    padding 12px
    border-top 1px solid #E3E3E3
    background #FFF
    margin-top 0
@media (max-width: 425px)
  .content
    padding 20px 16px 120px 16px
</style>
<style lang="stylus">
.product-inquiry-reg-modal
  .sweet-content
    padding 40px !important
  .sweet-content-content
    text-align left

@media (max-width:600px)
  .product-inquiry-reg-modal
    .sweet-content
      padding-top 66px !important
@media (max-width: 425px)
  .product-inquiry-reg-modal
    .sweet-modal
      border-radius 0 !important
    .sweet-content
      padding-top 50px !important
</style>
